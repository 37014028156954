<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center" class="row-alignment">
      <v-row justify="center" v-if="!editone && !edittwo">
        <v-col class="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6">
          <v-card v-if="UserProfileCard" class="profile-container-card">
            <v-row>
              <v-col cols="12" md="12">
                <v-card-text class="text-left">
                  <h2 class="title_style">Personal Information</h2>
                </v-card-text>
              </v-col>
              <v-col cols="12" md="12">
                <v-avatar
                  size="150"
                  v-if="!preview"
                  :class="avatar ? 'image_style' : 'preview_image_style'"
                >
                  <v-img :src="imageUrl" />
                </v-avatar>
                <v-avatar size="150" v-else>
                  <v-img :src="preview" />
                </v-avatar>
              </v-col>
              <v-col cols="12" md="12" class="text-center pt-0">
                <p class="name_style" data-cy="full-name">
                  {{ Show.firstName }} {{ Show.lastName }}
                </p>
                <p class="subtitle_data">
                  Account Type: {{ Show.accountType }}
                </p>
                <p class="subtitle_data">
                  {{ form.dateOfBirth }}
                </p>
              </v-col>
              <v-col cols="12" md="12" class="edit-btn-container">
                <md-button @click="editoneClicked" class="edit_btn_style"
                  >Edit</md-button
                >
              </v-col>
              <v-col cols="12">
                <v-divider justify="center" class="divider_style" />
              </v-col>
              <v-col md="11" class="text-left ml-7 mr-7">
                <p class="subtitle_style mb-6">Contact Information</p>

                <div class="mb-5">
                  <span class="subtitle_data"> Email Address: </span>
                  <span class="subtitle_data ml-1" v-if="user.email">
                    {{ user.email }}
                  </span>
                </div>

                <div class="mb-5">
                  <span class="subtitle_data" v-if="Show.phoneNumber">
                    Phone Number:
                  </span>

                  <span
                    class="subtitle_data font-weight-bold ml-1 mb-4"
                    v-if="Show.phoneNumber"
                  >
                    {{ Show.phoneNumber }}
                  </span>
                </div>
                <p class="subtitle_data" v-if="Show.billingAddressLine1">
                  Billing Address:
                </p>

                <p class="subtitle_data" v-if="Show.billingAddressLine1">
                  {{ Show.billingAddressLine1 }} <br />
                  {{ Show.billingAddressLine2 }}
                  <br v-if="Show.billingAddressLine2" />
                  {{ Show.billingAddressCity }} <br />
                  {{ Show.billingAddressState }}
                  <br v-if="Show.billingAddressState" />
                  {{ Show.billingAddressPostal }} <br />
                  {{ Show.billingAddressCountry }}
                </p>

                <p class="subtitle_data" v-if="Show.deliveryAddressLine1">
                  Delivery Address:
                </p>
                <p class="subtitle_data" v-if="Show.deliveryAddressLine1">
                  {{ Show.deliveryAddressLine1 }} <br />
                  {{ Show.deliveryAddressLine2 }}
                  <br v-if="Show.deliveryAddressLine2" />
                  {{ Show.deliveryAddressCity }} <br />
                  {{ Show.deliveryAddressState }}
                  <br v-if="Show.deliveryAddressLineState" />
                  {{ Show.deliveryAddressPostal }} <br />
                  {{ Show.deliveryAddressCountry }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-col class="user-details ml-2 col-12" v-if="editone">
        <div class="user-details-container">
          <v-card class="user-details-v-card">
            <v-card-title>
              <p class="title_style ml-3">Edit Personal Information</p>
            </v-card-title>
            <v-card-text class="pt-0">
              <v-form ref="profileDetails" class="mt-5">
                <v-container>
                  <v-row align="center" class="profile-row p-0">
                    <v-col
                      align="right"
                      class="avatar-container col-xs-12 col-sm-3 col-md-2 col-lg-2"
                    >
                      <v-avatar v-if="!preview" class="avatar">
                        <v-img :src="imageUrl" />
                      </v-avatar>
                      <v-avatar v-else class="avatar">
                        <v-img :src="preview" />
                      </v-avatar>
                    </v-col>
                    <v-col
                      class="upload-btn-container col-xs-6 col-sm-4 col-md-3 col-lg-3"
                    >
                      <md-button class="upload-new" @click="$refs.file.click()"
                        >Upload New
                      </md-button>

                      <md-field style="display:none; !important;">
                        <input
                          type="file"
                          ref="file"
                          accept="image/*"
                          @change="previewImage"
                        />
                      </md-field>
                    </v-col>
                    <v-col
                      class="password-btn-container col-xs-6 col-sm-5 col-md-4 col-lg-4"
                    >
                      <md-button
                        class="password_btn_style"
                        @click="editNotifications = true"
                      >
                        Update Email Preferences
                      </md-button>
                    </v-col>
                    <v-col
                      class="password-btn-container col-xs-6 col-sm-5 col-md-3 col-lg-3"
                    >
                      <md-button
                        class="password_btn_style"
                        @click="editPassword = true"
                      >
                        Change Password
                      </md-button>
                    </v-col>
                    <v-col cols="12">
                      <v-divider class="profile_divider_style" />
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        First Name
                      </p>
                      <v-text-field
                        v-model="form.firstName"
                        :rules="rules.firstNameRules"
                        data-cy="first-name-field"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Last Name
                      </p>
                      <v-text-field
                        v-model="form.lastName"
                        :rules="rules.lastNameRules"
                        data-cy="last-name-field"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">Email</p>
                      <v-text-field
                        v-model="form.email"
                        data-cy="email-field"
                        type="email"
                        outlined
                        dense
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Account Type
                      </p>
                      <v-text-field
                        v-model="form.accountType"
                        data-cy="email-field"
                        type="email"
                        outlined
                        dense
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-row class="number-and-date-container">
                      <v-col cols="6" md="6" sm="12">
                        <v-row no-gutters>
                          <p class="text-left ml-0 form-label-style">
                            Phone Number
                          </p>
                        </v-row>
                        <v-row no-gutters class="phone-number-row">
                          <v-col
                            cols="1"
                            md="3"
                            sm="3"
                            class="flag-container-col"
                          >
                            <vue-country-code
                              class="flag-container"
                              @onSelect="userNumberSelect"
                              :defaultCountry="country"
                            >
                            </vue-country-code>
                          </v-col>
                          <v-col style="margin-left: 12px">
                            <v-text-field
                              v-model="form.phoneNumber"
                              :rules="rules.phoneNumberRules"
                              :prefix="form.countryCode + ' '"
                              data-cy="phone-number-field"
                              v-on:keypress="PhoneInputNumbersOnly"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6" md="6" sm="12">
                        <p class="text-left mb-1 ml-0 form-label-style">
                          Date of Birth
                        </p>
                        <div class="calendar-field">
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="form.dateOfBirth"
                                prepend-icon="mdi-calendar"
                                clearable
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="form.dateOfBirth = null"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              class="calendar-field-date-picker"
                              v-model="form.dateOfBirth"
                              :active-picker.sync="activePicker"
                              :max="
                                new Date(
                                  Date.now() -
                                    new Date().getTimezoneOffset() * 60000
                                )
                                  .toISOString()
                                  .substr(0, 10)
                              "
                              min="1950-01-01"
                              @change="closePicker"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="mt-8">
                      <p class="title_style">Billing & Delivery Information</p>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-card-title class="addess-title"
                        >Delivery Address:</v-card-title
                      >
                      <div
                        class="address-container"
                        v-if="
                          form.deliveryAddressLine1 ||
                          form.deliveryAddressLine2 ||
                          form.deliveryAddressState
                        "
                      >
                        <div v-if="form.deliveryAddressLine1">
                          {{ form.deliveryAddressLine1 }}
                        </div>
                        <div v-if="form.deliveryAddressLine2">
                          {{ form.deliveryAddressLine2 }}
                        </div>
                        <div v-if="form.deliveryAddressCity">
                          {{ form.deliveryAddressCity }}
                        </div>
                        <div v-if="form.deliveryAddressState">
                          {{ form.deliveryAddressState }}
                        </div>
                        <div v-if="form.deliveryAddressPostal">
                          {{ form.deliveryAddressPostal }}
                        </div>
                        <div v-if="form.deliveryAddressCountry">
                          {{ form.deliveryAddressCountry }}
                        </div>
                      </div>
                      <v-card-actions>
                        <v-btn
                          icon
                          @click="showDeliveryAddress = !showDeliveryAddress"
                          class="show-btn"
                        >
                          <v-icon
                            >{{
                              showDeliveryAddress
                                ? "mdi-chevron-up"
                                : "mdi-pencil"
                            }}
                          </v-icon>
                        </v-btn>
                      </v-card-actions>
                      <v-expand-transition>
                        <div v-show="showDeliveryAddress">
                          <v-text-field
                            ref="address"
                            v-model="form.deliveryAddressLine1"
                            label="Address Line 1"
                            :required="true"
                            :rules="deliveryAddressLineRule"
                          ></v-text-field>
                          <v-text-field
                            ref="address"
                            v-model="form.deliveryAddressLine2"
                            label="Address Line 2"
                          ></v-text-field>
                          <v-text-field
                            ref="city"
                            v-model="form.deliveryAddressCity"
                            label="City"
                            :rules="deliveryAddressCityRule"
                          ></v-text-field>
                          <v-text-field
                            ref="state"
                            v-model="form.deliveryAddressState"
                            label="State/Province/Region"
                          ></v-text-field>
                          <v-text-field
                            ref="zip"
                            v-model="form.deliveryAddressPostal"
                            label="ZIP / Postal Code"
                            :rules="deliveryAddressPostalRule"
                          ></v-text-field>
                          <v-autocomplete
                            ref="country"
                            v-model="form.deliveryAddressCountry"
                            :items="countries"
                            label="Country"
                            :rules="deliveryAddressCountryRule"
                          >
                            <template v-slot:item="{ item }">
                              <span>{{ item }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span>{{ item }}</span>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-expand-transition>
                    </v-col>
                    <v-col v-if="billAsDelivery" cols="12" md="6">
                      <v-card-title class="addess-title"
                        >Billing Address:</v-card-title
                      >
                      <div
                        class="address-container"
                        v-if="
                          form.deliveryAddressLine1 ||
                          form.deliveryAddressLine2 ||
                          form.deliveryAddressState
                        "
                      >
                        <div v-if="form.deliveryAddressLine1">
                          {{ form.deliveryAddressLine1 }}
                        </div>
                        <div v-if="form.deliveryAddressLine2">
                          {{ form.deliveryAddressLine2 }}
                        </div>
                        <div v-if="form.deliveryAddressCity">
                          {{ form.deliveryAddressCity }}
                        </div>
                        <div v-if="form.deliveryAddressState">
                          {{ form.deliveryAddressState }}
                        </div>
                        <div v-if="form.deliveryAddressPostal">
                          {{ form.deliveryAddressPostal }}
                        </div>
                        <div v-if="form.deliveryAddressCountry">
                          {{ form.deliveryAddressCountry }}
                        </div>
                      </div>
                      <v-card-actions>
                        <v-btn
                          icon
                          @click="showDeliveryAddress = !showDeliveryAddress"
                          class="show-btn"
                        >
                          <v-icon
                            >{{
                              showDeliveryAddress
                                ? "mdi-chevron-up"
                                : "mdi-pencil"
                            }}
                          </v-icon>
                        </v-btn>
                      </v-card-actions>
                      <v-expand-transition>
                        <div v-show="showDeliveryAddress">
                          <v-text-field
                            ref="address"
                            v-model="form.deliveryAddressLine1"
                            label="Address Line 1"
                            :required="true"
                            :rules="deliveryAddressLineRule"
                          ></v-text-field>
                          <v-text-field
                            ref="address"
                            v-model="form.deliveryAddressLine2"
                            label="Address Line 2"
                          ></v-text-field>
                          <v-text-field
                            ref="city"
                            v-model="form.deliveryAddressCity"
                            label="City"
                            :rules="deliveryAddressCityRule"
                          ></v-text-field>
                          <v-text-field
                            ref="state"
                            v-model="form.deliveryAddressState"
                            label="State/Province/Region"
                          ></v-text-field>
                          <v-text-field
                            ref="zip"
                            v-model="form.deliveryAddressPostal"
                            label="ZIP / Postal Code"
                            :rules="deliveryAddressPostalRule"
                          ></v-text-field>
                          <v-autocomplete
                            ref="country"
                            v-model="form.deliveryAddressCountry"
                            :items="countries"
                            label="Country"
                            :rules="deliveryAddressCountryRule"
                          >
                            <template v-slot:item="{ item }">
                              <span>{{ item }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span>{{ item }}</span>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-expand-transition>
                    </v-col>
                    <v-col v-else cols="12" md="6">
                      <v-card-title class="addess-title"
                        >Billing Address:</v-card-title
                      >
                      <div
                        class="address-container"
                        v-if="
                          form.billingAddressLine1 ||
                          form.billingAddressLine2 ||
                          form.billingAddressState
                        "
                      >
                        <div v-if="form.billingAddressLine1">
                          {{ form.billingAddressLine1 }}
                        </div>
                        <div v-if="form.billingAddressLine2">
                          {{ form.billingAddressLine2 }}
                        </div>
                        <div v-if="form.billingAddressCity">
                          {{ form.billingAddressCity }}
                        </div>
                        <div v-if="form.billingAddressState">
                          {{ form.billingAddressState }}
                        </div>
                        <div v-if="form.billingAddressPostal">
                          {{ form.billingAddressPostal }}
                        </div>
                        <div v-if="form.billingAddressCountry">
                          {{ form.billingAddressCountry }}
                        </div>
                      </div>
                      <v-card-actions>
                        <v-btn
                          icon
                          @click="showBillingAddress = !showBillingAddress"
                          class="show-btn"
                        >
                          <v-icon
                            >{{
                              showBillingAddress
                                ? "mdi-chevron-up"
                                : "mdi-pencil"
                            }}
                          </v-icon>
                        </v-btn>
                      </v-card-actions>
                      <v-expand-transition>
                        <div v-show="showBillingAddress">
                          <v-text-field
                            ref="address"
                            v-model="form.billingAddressLine1"
                            label="Address Line 1"
                            :required="true"
                            :rules="billingAddressLineRule"
                          ></v-text-field>
                          <v-text-field
                            ref="address"
                            v-model="form.billingAddressLine2"
                            label="Address Line 2"
                          ></v-text-field>
                          <v-text-field
                            ref="city"
                            v-model="form.billingAddressCity"
                            label="City"
                            :rules="billingAddressCityRule"
                          ></v-text-field>
                          <v-text-field
                            ref="state"
                            v-model="form.billingAddressState"
                            label="State/Province/Region"
                          ></v-text-field>
                          <v-text-field
                            ref="zip"
                            v-model="form.billingAddressPostal"
                            label="ZIP / Postal Code"
                            :rules="billingAddressPostalRule"
                          ></v-text-field>
                          <v-autocomplete
                            ref="country"
                            v-model="form.billingAddressCountry"
                            :items="countries"
                            label="Country"
                            placeholder="Select..."
                            :rules="billingAddressCountryRule"
                          >
                            <template v-slot:item="{ item }">
                              <span>{{ item }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span>{{ item }}</span>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-expand-transition>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="profile-col">
                      <v-checkbox v-model="billAsDelivery" color="#47C0BE">
                        <template v-slot:label>
                          <div style="font-size: 15px; color: #442d65">
                            Use delivery address as billing address
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <md-button class="close-btn" @click="editoneClosed"
                >Close
              </md-button>
              <md-button class="save-btn" @click="updateUser">Save </md-button>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
      <popup-window
        title="Email Preferences"
        :active="editNotifications"
        :hasBack="false"
        noMargin
        :overlayOpacity="0.2"
        width="450px"
        maxWidth="100%"
        @popup-close="closeUpdateNotificationsForm"
        style="padding: 10px; align-items: center; align-content: center"
      >
        <div class="update-email-settings-form-container text-color">
          <v-form ref="updateNotificationsForm">
            <v-row>
              <v-col cols="12">
                <v-row class="align-center">
                  <v-col cols="6" style="text-align: left">
                    <span>Chat Messages</span>
                  </v-col>
                  <v-col cols="5">
                    <v-switch
                      v-model="emailSettings.chat"
                      data-cy="chat-messages-switch"
                      class="custom-switch"
                      @change="updateNotifications"
                    />
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="6" style="text-align: left">
                    <span>Digital Product Passport</span>
                  </v-col>
                  <v-col cols="5">
                    <v-switch
                      v-model="emailSettings.trace"
                      data-cy="digital-product-passport-switch"
                      class="custom-switch"
                      @change="updateNotifications"
                    />
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="6" style="text-align: left">
                    <span style="color: #442d65 !important">Collections</span>
                  </v-col>
                  <v-col cols="5">
                    <v-switch
                      v-model="emailSettings.collection"
                      data-cy="collections-switch"
                      class="custom-switch"
                      @change="updateNotifications"
                    />
                  </v-col>
                </v-row>
                <v-row class="align-center" style="text-align: left">
                  <v-col cols="6">
                    <span>Styles Activities</span>
                  </v-col>
                  <v-col cols="5">
                    <v-switch
                      v-model="emailSettings.style"
                      data-cy="styles-activities-switch"
                      class="custom-switch"
                      @change="updateNotifications"
                    />
                  </v-col>
                </v-row>
                <v-row class="align-center" style="text-align: left">
                  <v-col cols="6">
                    <span>Bill of Materials (BOM)</span>
                  </v-col>
                  <v-col cols="5">
                    <v-switch
                      v-model="emailSettings.bom"
                      data-cy="bill-of-materials-switch"
                      class="custom-switch"
                      @change="updateNotifications"
                    />
                  </v-col>
                </v-row>
                <v-row class="align-center" style="text-align: left">
                  <v-col cols="6">
                    <span>Style File</span>
                  </v-col>
                  <v-col cols="5">
                    <v-switch
                      v-model="emailSettings.styleFile"
                      data-cy="style-file-switch"
                      class="custom-switch"
                      @change="updateNotifications"
                    />
                  </v-col>
                </v-row>
                <v-row class="align-center" style="text-align: left">
                  <v-col cols="6">
                    <span>Certifications</span>
                  </v-col>
                  <v-col cols="5">
                    <v-switch
                      v-model="emailSettings.certification"
                      data-cy="certifications-switch"
                      class="custom-switch"
                      @change="updateNotifications"
                    />
                  </v-col>
                </v-row>
                <v-row class="align-center" style="text-align: left">
                  <v-col cols="6">
                    <span>Profile Updates</span>
                  </v-col>
                  <v-col cols="5">
                    <v-switch
                      v-model="emailSettings.profile"
                      data-cy="profile-updates-switch"
                      class="custom-switch"
                      @change="updateNotifications"
                    />
                  </v-col>
                </v-row>
                <v-row class="align-center" style="text-align: left">
                  <v-col cols="6">
                    <span>Costing</span>
                  </v-col>
                  <v-col cols="5">
                    <v-switch
                      v-model="emailSettings.costing"
                      data-cy="costing-switch"
                      class="custom-switch"
                      @change="updateNotifications"
                    />
                  </v-col>
                </v-row>
                <v-row class="align-center" style="text-align: left">
                  <v-col cols="6">
                    <span>Critical Path</span>
                  </v-col>
                  <v-col cols="5">
                    <v-switch
                      v-model="emailSettings.timeline"
                      data-cy="critical-path-switch"
                      class="custom-switch"
                      @change="updateNotifications"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </popup-window>

      <popup-window
        title="Edit Password"
        :active="editPassword"
        :hasBack="false"
        noMargin
        :overlayOpacity="0.2"
        width="600px"
        maxWidth="100%"
        @popup-close="closeUpdatePasswordForm"
      >
        <div class="update-password-form-container">
          <v-form ref="updatePasswordForm">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="password.old_password"
                  label="Old Password"
                  class="mt-8"
                  type="password"
                  data-cy="old-password-field"
                  :rules="rules.oldPasswordRules"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="password.new_password"
                  label="New Password"
                  class="password-textfield"
                  type="password"
                  data-cy="new-password-field"
                  :rules="rules.newPasswordRules"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <password-strength-bar
                  :password="password.new_password"
                  v-if="password.new_password"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="password.new_password_confirmation"
                  label="Confirm New Password"
                  class="password-textfield"
                  type="password"
                  data-cy="confirm-password-field"
                  :rules="rules.confirmPasswordRules"
                />
              </v-col>
            </v-row>
            <div v-if="password.new_password" class="password-policy-container">
              <password-policy :password="password.new_password" />
            </div>
            <v-col cols="12" class="text-right mt-5" style="padding-right: 0px">
              <ui-button
                id="cancelPasswordButton"
                class="danger"
                @click="closeUpdatePasswordForm"
                title="Cancel"
              />
              <ui-button
                id="updatePasswordButton"
                class="md-raised"
                style="margin-right: 0px"
                :disabled="saving"
                @click="updatePassword"
                title="Update Password"
              />
            </v-col>
          </v-form>
        </div>
      </popup-window>
    </v-row>
  </v-container>
</template>

<script>
import auth from "../../../auth";
import notification from "../../../notification";
import restAdapter from "../../../restAdapter";
import * as conf from "../../../conf.yml";
import countryList from "country-list";
import FileUpload from "../../../components/FileUpload.vue";
import utils from "../../../utils";
import defaultImage from "../../../assets/person.webp";
import codes from "country-codes-list";

import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  validatePassword,
} from "vuelidate/lib/validators";
import validationMixin from "../../../validation/validation_mixin";
import {
  confirmBox,
  resetLeaveAlert,
  setLeaveAlert,
} from "../../../utils/confirmBox";
import VComponent from "@/components/base/VComponent.vue";
import Vue from "vue";
import VueCountryCode from "vue-country-code";
import PasswordPolicy from "../../../components/base/PasswordPolicy.vue";
import PasswordStrengthBar from "@/components/PasswordStrengthBar.vue";

Vue.use(VueCountryCode);

/* eslint-disable */
export default {
  components: {
    VComponent,
    FileUpload,
    PasswordPolicy,
    PasswordStrengthBar,
  },
  name: "UserProfile",
  mixins: [validationMixin],
  validations: {
    password: {
      old_password: {
        required,
        validatePassword,
      },
      new_password: {
        required,
        validatePassword,
      },
      new_password_confirmation: {
        required,
        sameAsPassword: sameAs("new_password"),
      },
    },
  },
  data() {
    return {
      billAsDelivery: false,
      country: "AF",
      preview: null,
      image: null,
      oldNum: 0,
      user: {},
      imageUrl: defaultImage,
      avatar: false,
      socialAvatar: null,
      countries: [],
      countryCodes: [],
      arr: [],
      countryCodeList: [],
      showBillingAddress: true,
      showDeliveryAddress: true,
      activePicker: null,
      menu: false,
      yearArray: [],
      editone: false,
      edittwo: false,
      UserProfileCard: true,
      editPassword: false,
      editNotifications: false,
      savingNotifications: false,
      emailSettings: {
        chat: true,
        trace: true,
        collection: true,
        style: true,
        bom: true,
        styleFile: true,
        certification: true,
        profile: true,
        costing: true,
        timeline: true,
      },
      temp: {
        billingAddressLine1: null,
        billingAddressLine2: null,
        billingAddressCity: null,
        billingAddressState: null,
        billingAddressPostal: null,
        billingAddressCountry: null,
      },
      Show: {
        firstName: null,
        lastName: null,
        email: null,
        accountType: null,
        phoneNumber: null,
        dateOfBirth: null,
        billingAddressLine1: null,
        billingAddressLine2: null,
        billingAddressCity: null,
        billingAddressState: null,
        billingAddressPostal: null,
        billingAddressCountry: null,
        deliveryAddressLine1: null,
        deliveryAddressLine2: null,
        deliveryAddressCity: null,
        deliveryAddressState: null,
        deliveryAddressPostal: null,
        deliveryAddressCountry: null,
      },
      form: {
        firstName: null,
        lastName: null,
        email: null,
        accountType: null,
        phoneNumber: null,
        countryCode: null,
        id: null,
        dateOfBirth: null,
        billingAddressLine1: null,
        billingAddressLine2: null,
        billingAddressCity: null,
        billingAddressState: null,
        billingAddressPostal: null,
        billingAddressCountry: null,
        deliveryAddressLine1: null,
        deliveryAddressLine2: null,
        deliveryAddressCity: null,
        deliveryAddressState: null,
        deliveryAddressPostal: null,
        deliveryAddressCountry: null,
      },
      password: {
        old_password: null,
        new_password: null,
        new_password_confirmation: null,
      },
      rules: {
        firstNameRules: [
          (value) => !!value || "First name is required",

          (value) =>
            (value && value.length >= 3) ||
            "First name should have at least 3 characters",

          (value) =>
            (value && value.length <= 150) ||
            "First name should have maximum 150 characters",

          (value) =>
            (value && /^[a-zA-Z0-9_\s]+$/.test(value)) ||
            "First name should contain only letters, numbers and underscores",
        ],
        lastNameRules: [
          (value) => !!value || "Last name is required.",
          (value) =>
            (value && value.length >= 3) ||
            "Last name should have at least 3 characters",
          (value) =>
            (value && value.length <= 150) ||
            "Last name should have maximum 150 characters",
          (value) =>
            (value && /^[a-zA-Z0-9_\s]+$/.test(value)) ||
            "First name should contain only letters, numbers and underscores",
        ],
        phoneNumberRules: [
          (value) =>
            !value ||
            (value && value.length >= 7 && value.length <= 16) ||
            "Phone number should have minimum 7 digits and maximum 16 digits",
        ],
        oldPasswordRules: [(value) => !!value || "Old password is required"],
        newPasswordRules: [
          (value) => !!value || "New password is required",

          (value) =>
            (value && value.length >= 6) ||
            "New password should have at least 6 characters",

          (value) =>
            (value && /\d/.test(value)) ||
            "New password should include at least one number",

          (value) =>
            (value && /[a-z]/.test(value)) ||
            "New password should include at least one lower case character",

          (value) =>
            (value && /[A-Z]/.test(value)) ||
            "New password should include at least one upper case character",
        ],
        confirmPasswordRules: [
          (value) => !!value || "Confirm password is required",

          (value) =>
            (value && value === this.password.new_password) ||
            "Confirm password should match with new password",
        ],
      },
      ignoreFormEdit: false,
      ignorePasswordEdit: false,
      routeLeaveAlertForm: false,
      routeLeaveAlertPassword: false,
    };
  },

  watch: {
    form: {
      handler: function () {
        if (!this.ignoreFormEdit) {
          this.routeLeaveAlertForm = true;
        }
        this.ignoreFormEdit = setLeaveAlert(this.ignoreFormEdit);
      },
      deep: true,
    },
    password: {
      handler: function () {
        if (!this.ignorePasswordEdit) {
          this.routeLeaveAlertPassword = true;
        }
        this.ignorePasswordEdit = setLeaveAlert(this.ignorePasswordEdit);
      },
      deep: true,
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    userNumberSelect({ name, iso2, dialCode }) {
      this.form.countryCode = "+" + dialCode;
    },
    getUser() {
      this.getEmailSettings();
      restAdapter
        .get("/api/user/display_user_profile/" + auth.user.id, {
          preview: false,
        })
        .then((res) => {
          this.form.firstName = res.data.user.first_name;
          this.form.lastName = res.data.user.last_name;
          this.form.email = res.data.user.email;
          this.form.accountType = res.data.user.role.display_name;
          this.form.phoneNumber = res.data.user.phone_number;
          this.form.dateOfBirth = this.formatDate(res.data.user.date_of_birth);

          this.Show.firstName = res.data.user.first_name;
          this.Show.lastName = res.data.user.last_name;
          this.Show.email = res.data.user.email;
          this.Show.accountType = res.data.user.role.display_name;
          this.billAsDelivery = res.data.user.same_as_delivery;

          this.Show.phoneNumber = res.data.user.phone_number;
          this.Show.dateOfBirth = this.formatDate(res.data.user.date_of_birth);

          if (this.Show.phoneNumber === null) {
            this.form.countryCode = "+93";
          } else {
            this.Show.phoneNumber = this.Show.phoneNumber.toString();

            this.form.countryCode = this.Show.phoneNumber
              .split(" ")[0]
              .substring(-1);
            this.form.phoneNumber = this.Show.phoneNumber.split(" ")[1];
          }

          const countryCodes = codes.customList(
            "countryCode",
            "+{countryCallingCode}"
          );
          const obj = Object.entries(countryCodes);

          const selectedCountry = obj.find(
            (country) => country[1] == this.form.countryCode
          );
          this.country = (selectedCountry && selectedCountry[0]) || null;

          this.temp.billingAddressLine1 = res.data.user.billing_address_line1;
          this.form.billingAddressLine1 = res.data.user.billing_address_line1;
          this.temp.billingAddressLine2 = res.data.user.billing_address_line2;
          this.form.billingAddressLine2 = res.data.user.billing_address_line2;
          this.form.billingAddressCity = res.data.user.billing_address_city;
          this.temp.billingAddressCity = res.data.user.billing_address_city;
          this.form.billingAddressState = res.data.user.billing_address_state;
          this.temp.billingAddressState = res.data.user.billing_address_state;
          this.temp.billingAddressPostal =
            res.data.user.billing_address_post_code;
          this.form.billingAddressPostal =
            res.data.user.billing_address_post_code;
          this.temp.billingAddressCountry =
            res.data.user.billing_address_country;
          this.form.billingAddressCountry =
            res.data.user.billing_address_country;

          if (res.data.user.same_as_delivery) {
            this.Show.billingAddressLine1 =
              res.data.user.delivery_address_line1;
            this.Show.billingAddressLine2 =
              res.data.user.delivery_address_line2;
            this.Show.billingAddressCity = res.data.user.delivery_address_city;
            this.Show.billingAddressPostal =
              res.data.user.delivery_address_post_code;
            this.Show.billingAddressState =
              res.data.user.delivery_address_state;
            this.Show.billingAddressCountry =
              res.data.user.delivery_address_country;
          } else {
            this.Show.billingAddressLine1 = res.data.user.billing_address_line1;
            this.Show.billingAddressLine2 = res.data.user.billing_address_line2;
            this.Show.billingAddressCity = res.data.user.billing_address_city;
            this.Show.billingAddressState = res.data.user.billing_address_state;
            this.Show.billingAddressPostal =
              res.data.user.billing_address_post_code;
            this.Show.billingAddressCountry =
              res.data.user.billing_address_country;
          }

          this.form.deliveryAddressLine1 = res.data.user.delivery_address_line1;
          this.Show.deliveryAddressLine1 = res.data.user.delivery_address_line1;

          this.form.deliveryAddressLine2 = res.data.user.delivery_address_line2;
          this.Show.deliveryAddressLine2 = res.data.user.delivery_address_line2;

          this.form.deliveryAddressCity = res.data.user.delivery_address_city;
          this.Show.deliveryAddressCity = res.data.user.delivery_address_city;

          this.form.deliveryAddressState = res.data.user.delivery_address_state;
          this.Show.deliveryAddressState = res.data.user.delivery_address_state;

          this.form.deliveryAddressPostal =
            res.data.user.delivery_address_post_code;
          this.Show.deliveryAddressPostal =
            res.data.user.delivery_address_post_code;

          this.form.deliveryAddressCountry =
            res.data.user.delivery_address_country;
          this.Show.deliveryAddressCountry =
            res.data.user.delivery_address_country;

          if (res.data.user.avatar == "users/default.png" && auth.user.avatar) {
            this.avatar = true;
            this.imageUrl = auth.user.avatar;
          } else {
            restAdapter
              .getWithOutCache("/api/user/image_show/" + this.user.id)
              .then((res) => {
                if (res.data.image) {
                  this.avatar = false;
                  this.imageUrl = res.data.image;
                }
              });
          }

          this.ignoreFormEdit = true;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong");
          }
        });
    },
    PhoneInputNumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateProfilePicture() {
      if (!this.$refs.file.files.length) {
        notification.error("Please select an image");
      }

      if (this.$refs.file.files[0].size > 2 * 1024 * 1024) {
        notification.error("File size should be less than 2 MB");
        this.saving = false;
        return;
      }

      const file = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append("file", file);

      restAdapter
        .post("/api/user/update_profile_picture", formData)
        .then((res) => {
          if (res.status === 200) {
            this.$emit("file-uploaded");
            notification.success("Profile Picture Updated Successfully");
            this.getImage();
            this.$refs.file.value = null;
          } else {
            notification.error("Something went wrong");
          }
        });
    },
    getImage() {
      this.imageUrl = this.baseUrl + "/api/user/image_show/" + this.user.id;
    },
    updateUser() {
      if (!this.$refs.profileDetails.validate()) {
        return;
      }
      if (this.billAsDelivery) {
        this.form.billingAddressLine1 = this.form.deliveryAddressLine1;
        this.form.billingAddressLine2 = this.form.deliveryAddressLine2;
        this.form.billingAddressCity = this.form.deliveryAddressCity;
        this.form.billingAddressState = this.form.deliveryAddressState;
        this.form.billingAddressPostal = this.form.deliveryAddressPostal;
        this.form.billingAddressCountry = this.form.deliveryAddressCountry;
      }
      const formData = new FormData();
      formData.append("same_as_delivery", this.billAsDelivery ? 1 : 0);
      formData.append("first_name", this.form.firstName);
      formData.append("last_name", this.form.lastName);
      if (this.form.phoneNumber != null)
        formData.append(
          "phone_number",
          this.form.countryCode + " " + this.form.phoneNumber
        );
      if (this.form.dateOfBirth != null)
        formData.append(
          "date_of_birth",
          this.formatDate(this.form.dateOfBirth)
        );
      if (this.billAsDelivery) {
        if (this.temp.billingAddressLine1 != null) {
          formData.append(
            "billing_address_line1",
            this.temp.billingAddressLine1
          );
          formData.append("billing_address_city", this.temp.billingAddressCity);
          formData.append(
            "billing_address_postal",
            this.temp.billingAddressPostal
          );
          formData.append(
            "billing_address_country",
            this.temp.billingAddressCountry
          );
          if (this.temp.billingAddressLine2 != null)
            formData.append(
              "billing_address_line2",
              this.temp.billingAddressLine2
            );
          if (this.temp.billingAddressState != null)
            formData.append(
              "billing_address_state",
              this.temp.billingAddressState
            );
        }
      } else {
        if (this.form.billingAddressLine1 != null) {
          formData.append(
            "billing_address_line1",
            this.form.billingAddressLine1
          );
          formData.append("billing_address_city", this.form.billingAddressCity);
          formData.append(
            "billing_address_postal",
            this.form.billingAddressPostal
          );
          formData.append(
            "billing_address_country",
            this.form.billingAddressCountry
          );
          if (this.form.billingAddressLine2 != null)
            formData.append(
              "billing_address_line2",
              this.form.billingAddressLine2
            );
          if (this.form.billingAddressState != null)
            formData.append(
              "billing_address_state",
              this.form.billingAddressState
            );
        }
      }

      if (this.form.deliveryAddressLine1 != null) {
        formData.append(
          "delivery_address_line1",
          this.form.deliveryAddressLine1
        );
        formData.append("delivery_address_city", this.form.deliveryAddressCity);
        formData.append(
          "delivery_address_postal",
          this.form.deliveryAddressPostal
        );
        formData.append(
          "delivery_address_country",
          this.form.deliveryAddressCountry
        );
        if (this.form.deliveryAddressLine2 != null)
          formData.append(
            "delivery_address_line2",
            this.form.deliveryAddressLine2
          );
        if (this.form.deliveryAddressState != null)
          formData.append(
            "delivery_address_state",
            this.form.deliveryAddressState
          );
      }
      restAdapter
        .post("/api/update_profile", formData)
        .then(() => {
          notification.success("User Information Updated");

          let userData = auth.user;
          userData.firstName = this.form.firstName;
          userData.lastName = this.form.lastName;
          userData.phoneNumber = this.form.phoneNumber;
          auth.updateAuth(userData, true);
          this.getUser();
          this.editone = false;
          this.userCards();
          this.routeLeaveAlertForm = false;
          if (!this.routeLeaveAlertPassword) {
            resetLeaveAlert();
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    previewImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
        this.updateProfilePicture();
      }
    },
    updatePassword() {
      if (!this.$refs.updatePasswordForm.validate()) {
        return;
      }
      restAdapter
        .post("/api/update_password", this.password)
        .then(() => {
          notification.success("Password updated.");
          this.ignorePasswordEdit = true;
          this.$refs.updatePasswordForm.reset();
          this.routeLeaveAlertPassword = false;
          if (!this.routeLeaveAlertForm) {
            resetLeaveAlert();
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status == 422 &&
            error.response.data &&
            error.response.data.errors
          ) {
            notification.errors(error.response.data.errors);
          } else if (error.response && error.response.status == 401) {
            const errors = {
              old_password: ["The old password is incorrect"],
            };
            notification.errors(errors);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    closeUpdatePasswordForm() {
      this.$refs.updatePasswordForm.reset();
      this.$refs.updatePasswordForm.resetValidation();
      this.editPassword = false;
    },
    getEmailSettings() {
      restAdapter
        .get("/api/email_settings")
        .then((res) => {
          if (res.data?.email_settings) {
            const emailSettings = res.data.email_settings;
            this.emailSettings = {
              chat: emailSettings.chat,
              trace: emailSettings.trace,
              collection: emailSettings.collection,
              style: emailSettings.style,
              bom: emailSettings.bom,
              styleFile: emailSettings.style_file,
              certification: emailSettings.certification,
              profile: emailSettings.profile,
              costing: emailSettings.costing,
              timeline: emailSettings.timeline,
            };
          }
        })
        .catch((error) => {
          notification.error("Something went wrong, please try again later.");
        });
    },
    closeUpdateNotificationsForm() {
      this.editNotifications = false;
    },
    updateNotifications() {
      this.savingNotifications = true;
      restAdapter
        .post("/api/email_settings", {
          chat: this.emailSettings.chat,
          trace: this.emailSettings.trace,
          collection: this.emailSettings.collection,
          style: this.emailSettings.style,
          bom: this.emailSettings.bom,
          style_file: this.emailSettings.styleFile,
          certification: this.emailSettings.certification,
          profile: this.emailSettings.profile,
          costing: this.emailSettings.costing,
          timeline: this.emailSettings.timeline,
        })
        .then(() => {
          notification.success("Email settings updated.");
          this.savingNotifications = false;
        })
        .catch((error) => {
          this.savingNotifications = false;
          notification.error("Something went wrong, please try again later.");
        });
    },

    closePicker(date) {
      this.$refs.menu.save(date);
    },
    formatDate(date) {
      if (date !== null) {
        return this.$moment(new Date(date)).format("YYYY-MM-DD");
      }
      return null;
    },
    changePhoneNumber() {
      this.form.phoneNumber = "";
    },

    editoneClicked() {
      this.editone = true;
      this.UserProfileCard = false;
    },
    editoneClosed() {
      this.editone = false;
      this.userCards();
    },
    userCards() {
      this.companyOverview = true;
      this.contactInfo = true;
      this.machineInfo = true;
      this.companyGallery = true;
      this.UserProfileCard = true;
    },
  },
  computed: {
    billingAddressLineRule() {
      return [
        (v) => {
          if (
            !this.form.billingAddressLine1 &&
            !this.form.billingAddressLine2 &&
            !this.form.billingAddressState &&
            !this.form.billingAddressCity &&
            !this.form.billingAddressPostal &&
            !this.form.billingAddressCountry
          ) {
            return true;
          } else if (!this.form.billingAddressLine1) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    billingAddressCityRule() {
      return [
        (v) => {
          if (
            !this.form.billingAddressLine1 &&
            !this.form.billingAddressLine2 &&
            !this.form.billingAddressState &&
            !this.form.billingAddressCity &&
            !this.form.billingAddressPostal &&
            !this.form.billingAddressCountry
          ) {
            return true;
          } else if (!this.form.billingAddressCity) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    billingAddressPostalRule() {
      return [
        (v) => {
          if (
            !this.form.billingAddressLine1 &&
            !this.form.billingAddressLine2 &&
            !this.form.billingAddressState &&
            !this.form.billingAddressCity &&
            !this.form.billingAddressPostal &&
            !this.form.billingAddressCountry
          ) {
            return true;
          } else if (!this.form.billingAddressPostal) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    billingAddressCountryRule() {
      return [
        (v) => {
          if (
            !this.form.billingAddressLine1 &&
            !this.form.billingAddressLine2 &&
            !this.form.billingAddressState &&
            !this.form.billingAddressCity &&
            !this.form.billingAddressPostal &&
            !this.form.billingAddressCountry
          ) {
            return true;
          } else if (!this.form.billingAddressCountry) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    deliveryAddressLineRule() {
      return [
        (v) => {
          if (
            !this.form.deliveryAddressLine1 &&
            !this.form.deliveryAddressLine2 &&
            !this.form.deliveryAddressState &&
            !this.form.deliveryAddressCity &&
            !this.form.deliveryAddressPostal &&
            !this.form.deliveryAddressCountry
          ) {
            return true;
          } else if (!this.form.deliveryAddressLine1) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    deliveryAddressCityRule() {
      return [
        (v) => {
          if (
            !this.form.deliveryAddressLine1 &&
            !this.form.deliveryAddressLine2 &&
            !this.form.deliveryAddressState &&
            !this.form.deliveryAddressCity &&
            !this.form.deliveryAddressPostal &&
            !this.form.deliveryAddressCountry
          ) {
            return true;
          } else if (!this.form.deliveryAddressCity) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    deliveryAddressPostalRule() {
      return [
        (v) => {
          if (
            !this.form.deliveryAddressLine1 &&
            !this.form.deliveryAddressLine2 &&
            !this.form.deliveryAddressState &&
            !this.form.deliveryAddressCity &&
            !this.form.deliveryAddressPostal &&
            !this.form.deliveryAddressCountry
          ) {
            return true;
          } else if (!this.form.deliveryAddressPostal) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    deliveryAddressCountryRule() {
      return [
        (v) => {
          if (
            !this.form.deliveryAddressLine1 &&
            !this.form.deliveryAddressLine2 &&
            !this.form.deliveryAddressState &&
            !this.form.deliveryAddressCity &&
            !this.form.deliveryAddressPostal &&
            !this.form.deliveryAddressCountry
          ) {
            return true;
          } else if (!this.form.deliveryAddressCountry) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
  },

  mounted() {
    this.user = { ...auth.user };
    this.baseUrl = conf.default.server.host;
    this.getUser();

    this.socialAvatar = auth.user.avatar;
    this.countries = countryList.getNames();

    const year = new Date().getFullYear();
    this.yearArray = Array.from(
      { length: year - 1900 },
      (value, index) => 1901 + index
    );
  },

  beforeRouteLeave(to, from, next) {
    confirmBox(this.$store.state.routeLeaveAlert, next);
  },
};
</script>
<style lang="scss" scoped>
.mr-0 {
  margin-left: 5%;
}

.inlineBlock {
  min-width: 10%;
  float: left;
  padding: 5px;
}

.tooltip {
  position: relative;
  display: inline-block; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #37474f;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  width: 120px;
  top: 80%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.update-password-form-container {
  padding: 10px 8% 10px 8%;
}

.formLabel {
  font-size: 1rem !important;
}

.md-button.md-theme-default.md-raised:not([disabled]).md-primary {
  color: #fff;
  color: var(--md-theme-default-text-primary-on-primary, #fff);
  background-color: $tableBorder !important;
}
</style>
<style lang="scss">
.v-dialog-custom {
  width: 50% !important;
}

@media only screen and (max-width: 600px) {
  .v-dialog-custom {
    width: 100% !important;
  }
}

.v-dialog-data {
  width: 80% !important;
  background-color: #fff !important;
}

.v-card-data {
  max-height: 65vh !important;
  overflow: auto;
}

.title_style {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $tableBorder;
  text-decoration-line: underline;
  text-align: left;
}

.custom-switch .v-label {
  color: #473068 !important;
}
.custom-switch {
  margin-top: -5px;
  margin-bottom: -5px;
  margin-left: 80px;
  align-items: center;
  color: #473068 !important;
}
.text-color {
  color: #473068;
}
.text-color span {
  color: #473068;
}
.update-email-settings-form-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.name_style {
  color: $tableBorder;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin: 12px 0px 0px 0px;
}

.image_style {
  border: 7px solid $paginateText;
  box-shadow: 0px 6.5045px 4.8836px 1px rgb(61 77 135 / 10%);
}

.preview_image_style {
  border: unset;
  box-shadow: unset;
}

.divider_style {
  border-color: $primaryDark !important;
  margin: 25px 25px 15px 25px;
}

.dialog_divider_style {
  border-color: $primaryDark !important;
  margin: 20px 0px 50px 0px;
}

.profile_divider_style {
  border-color: $primaryDark !important;
  margin: 20px 0px 20px 0px;
}

.company_overview_divider_style {
  border-color: $primaryDark !important;
  margin: -15px 25px 15px 32px;
}

.subtitle_style {
  color: $tableBorder;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.subtitle_data {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: $tableBorder;
}

.paragraph_data {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: $tableBorder;
}

.btn-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  padding: 0px 5px 0px 5px;
}
.upload-btn-container {
  display: flex;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  padding: 0;
}

.password-btn-container {
  display: flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}

.edit-btn-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: -25px;
}

.edit_btn_style {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
  padding: 0px 25px 0px 25px;
  // text-transform: capitalize;
}

.btn_style {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
  padding: 0px 25px 0px 25px;
  margin-right: 25px;
  // text-transform: capitalize;
}

.password_btn_style {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
}

.align-center {
  display: flex;
  justify-content: center;
  margin-bottom: -25px !important;
}

.user-details-container {
  background-color: $paginateText;
  display: flex;
  justify-content: center;
}

.user-details-v-card {
  max-width: 70% !important;
  box-shadow: none !important;
}

.company-details-v-card {
  max-width: 70% !important;
  box-shadow: none !important;
}

.address-container {
  background-color: $paginateBackground;
  border: 0.5px solid $tableBorder;
  border-radius: 3px;
  text-align: left;
  padding: 20px 50px;
  color: $tableBorder;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.theme--light.v-input input {
  color: $primaryDark !important;
}

.addess-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $tableBorder;
  margin-bottom: 10px;
  margin-left: -15px;
}

.close-btn {
  color: $tableBorder !important;
  background-color: $paginateText;
  border: 1px solid $tableBorder;
  border-radius: 3px;
  height: 40px;
  // padding: 0px 15px 0px 15px;
  // text-transform: capitalize;
}

.save-btn {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
  padding: 0px 25px 0px 25px;
  // text-transform: capitalize;
}

// .edit-password {
//   padding: 30px 55px 10px 55px;
//   background: $paginateBackground;
//   border: 1px solid $tableBorder;
//   border-radius: 8px;
// }

.password-policy-container {
  margin-top: 30px;
  background: rgba(71, 48, 104, 0.05);
  border: 0.5px solid $tableBorder;
  border-radius: 3px;
  padding-bottom: 12px;
  text-align: left;
}

.password-fields-container {
  padding: 0px;
}

.v-application--is-ltr .v-text-field .v-label {
  color: $primaryDark !important;
}

.v-text-field > .v-input__control > .v-input__slot::before {
  border-color: $primaryDark !important;
}

// .v-overlay__scrim{
//   opacity: unset !important;
//   background: rgba(15, 5, 29, 0.78) !important;
//   backdrop-filter: blur(12px);
// }

.theme--dark.v-overlay {
  background-color: rgba(15, 5, 29, 0.78) !important;
}

.password-textfield {
  margin-top: -20px !important;
}

.form-label-style {
  color: $tableBorder;
  opacity: 0.7;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--has-state
  )
  > .v-input__control
  > .v-input__slot
  fieldset {
  border: 0.5px solid $tableBorder !important;
  border-radius: 3px !important;
}

.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1.5px solid $tableBorder !important;
  border-radius: 3px !important;
}

.products-container {
  background-color: #f6f1ff;
  width: auto;
  height: auto;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: left;
  padding: 22px 30px;
  margin: -12px 25px;
  overflow-y: auto;
}

.data-margin {
  margin: 15px 0px;
}

.data-container-card {
  background: $paginateText;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
}

.images-container-card {
  background: $paginateText;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  padding-bottom: 30px;
}

.profile-container-card {
  background: $paginateBackground !important;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border: 1px solid $tableBorder !important;
  border-radius: 7px !important;
  padding-bottom: 30px;
}

.machines-container-card {
  background: $paginateText;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  padding-bottom: 60px;
}

.profile-card-style {
  margin-right: 10px;
  width: 100%;
  min-height: 52px;
  border-radius: 3px;
  border: 0.5px solid $light;
  background: $light;
  display: flex;
  align-items: center;
  text-align: left;
}
.avatar {
  width: 100% !important;
  height: unset !important;
  max-width: 150px;
  aspect-ratio: 1/1;
}
.upload-new {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
}

@media only screen and (max-width: 840px) {
  .upload-new {
    font-size: 11px;
  }
  .password_btn_style {
    font-size: 11px;
  }
}

@media only screen and (max-width: 680px) {
  .upload-new {
    width: 91% !important;
    font-size: 9px;
  }
  .password_btn_style {
    width: 100% !important;
    font-size: 9px;
  }
  .avatar {
    max-width: 77px;
  }
}

.machines-data-container {
  padding: 0px 50px;
}

.amount {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-hr {
  border-color: $primaryDark !important;
  margin: 10px 0px;
}

.machines-container-alignment {
  margin: -8px 0px;
}

.machine-type {
  margin: 0px 0px 0px 12px;
}

.theme--light.v-select .v-select__selections {
  color: $primaryDark !important;
}

.theme--light.v-input textarea {
  color: $primaryDark !important;
}

.team-details {
  padding: 25px;
  margin-top: -15px;
}

.machine-details {
  padding: 25px;
}

.add-remove-btn {
  height: 32px !important;
  width: 32px !important;
  box-shadow: none;
  border-radius: 3.99038px;
}

.title_align {
  margin-left: 16px;
}

.vue-country-select {
  border: 1px solid $tableBorder !important;
}

.vue-country-select:focus-within {
  border-color: $success !important;
}

.more-info-style {
  background: rgba(112, 208, 206, 0.18);
  border-radius: 3px;
  margin-right: 10px;
  width: 80%;
  min-height: 52px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 32px;
  line-break: anywhere;
}

.profile-col {
  margin: -12px 0px 0px 0px;
}

.md-field.md-theme-default:after {
  background-color: $tableBorder;
}

.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: $tableBorder !important;
}

.selection-field::v-deep .v-select__selections {
  color: $tableBorder;
}

.number-and-date-container {
  padding: 12px;
}

.theme--light.v-icon {
  color: $primaryDark;
}

.overview-subtitle {
  margin: 0px 15px;
}

.row-alignment {
  margin: -12px 20px;
}

.card-height {
  height: 100%;
}
</style>

<style scoped>
.v-application .v-list .v-list-item--active {
  background-color: #e2daed !important;
}

.theme--light.v-list {
  background: #f3edf7;
  color: #473068;
}

.show-btn {
  background-color: #473068 !important;
}

.calendar-field::v-deep .v-text-field--outlined fieldset {
  height: 45px !important;
}

.calendar-field::v-deep .v-text-field--outlined .v-text-field__slot {
  height: 42px !important;
}

.calendar-field::v-deep .v-text-field--enclosed .v-input__append-inner {
  margin-top: 9px !important;
}

.calendar-field::v-deep .v-text-field--enclosed .v-input__prepend-outer {
  margin-top: 9px !important;
}

.calendar-field-date-picker {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.flag-container {
  height: 40px;
}

.flag-container-col {
  display: contents;
}

.phone-number-row {
  margin: -12px 0px 0px 2px;
}
</style>
